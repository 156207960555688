(() => {
  const hamburger = document.querySelector(".hamburger");
  const mobileNav = document.querySelector(".mobile-nav");
  const mobileNavClose = document.querySelector(".mobile-nav__close");

  if (hamburger && mobileNav) {
    hamburger.addEventListener("click", (e) => {
      e.preventDefault();

      mobileNav.style.display = "block";
    });
  }

  if (mobileNavClose) {
    mobileNavClose.addEventListener("click", (e) => {
      e.preventDefault();

      mobileNav.style.display = "none";
    });
  }
})();
