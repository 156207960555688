(() => {
  const gastenboekEntries = document.querySelector(".gastenboek__entries > ul");
  const gastenboekForm = document.querySelector(".gastenboek-form");
  const prevEntries = JSON.parse(localStorage.getItem("gastenboek"));
  const entries = [];

  if (gastenboekEntries && gastenboekForm) {
    if (prevEntries) {
      prevEntries.forEach((entry) => {
        gastenboekEntries.insertAdjacentHTML(
          "beforeend",
          `<li class="gastenboek__entry"><span>${entry.name}:</span> ${entry.message}</li>`
        );
      });
    }

    const onFormSubmit = (event) => {
      event.preventDefault();

      const name = document.querySelector("#name");
      const message = document.querySelector("#message");

      if (name && message) {
        gastenboekEntries.insertAdjacentHTML(
          "beforeend",
          `<li class="gastenboek__entry"><span>${name.value}:</span> ${message.value}</li>`
        );
        entries.push({
          name: name.value,
          message: message.value,
        });

        localStorage.setItem("gastenboek", JSON.stringify(entries));

        name.value = "";
        message.value = "";
      }

      return false;
    };

    setTimeout(() => {
      gastenboekEntries.insertAdjacentHTML(
        "beforeend",
        `<li class="gastenboek__entry"><span>Ludwig:</span> Gefelicteert met deze mooie webstek</li>`
      );
    }, 5000);

    setTimeout(() => {
      gastenboekEntries.insertAdjacentHTML(
        "beforeend",
        `<li class="gastenboek__entry"><span>Sander:</span> Hallo</li>`
      );
    }, 10000);

    gastenboekForm.addEventListener("submit", onFormSubmit);
  }
})();
