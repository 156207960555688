(() => {
  const counter = document.querySelector(".counter");

  if (counter) {
    const digit0 = counter.querySelector(".digit-0");
    const digit1 = counter.querySelector(".digit-1");
    const digit2 = counter.querySelector(".digit-2");
    const digit3 = counter.querySelector(".digit-3");
    const digit4 = counter.querySelector(".digit-4");
    const digit5 = counter.querySelector(".digit-5");
    const digit6 = counter.querySelector(".digit-6");
    const digit7 = counter.querySelector(".digit-7");

    if (
      digit0 &&
      digit1 &&
      digit2 &&
      digit3 &&
      digit4 &&
      digit5 &&
      digit6 &&
      digit7
    ) {
      let counter = 1000;

      const previousDigits = localStorage.getItem("counter-digits");

      if (previousDigits) {
        const splittedDigits = previousDigits.split("/");
        digit4.innerHTML = splittedDigits[0];
        digit5.innerHTML = splittedDigits[1];
        digit6.innerHTML = splittedDigits[2];
        digit7.innerHTML = splittedDigits[3];
      }

      const getRandomInt = (min, max) => {
        min = Math.ceil(min);
        max = Math.floor(max);

        return Math.floor(Math.random() * (max - min + 1)) + min;
      };

      let interval;

      const onInterval = () => {
        clearInterval(interval);

        const digit4Value = +digit4.innerHTML;
        const digit5Value = +digit5.innerHTML;
        const digit6Value = +digit6.innerHTML;
        const digit7Value = +digit7.innerHTML;

        if (digit5Value === 9) {
          digit4.innerHTML = digit4Value + 1;
          digit5.innerHTML = 0;
        }

        if (digit6Value === 9) {
          digit5.innerHTML = digit5Value + 1;
          digit6.innerHTML = 0;
        }

        if (digit7Value === 9) {
          digit6.innerHTML = digit6Value + 1;
          digit7.innerHTML = 0;
        } else {
          digit7.innerHTML = digit7Value + 1;
        }

        counter = getRandomInt(3, 8) * 1000;
        interval = setInterval(onInterval, counter);
        localStorage.setItem(
          "counter-digits",
          `${+digit4.innerHTML}/${+digit5.innerHTML}/${+digit6.innerHTML}/${+digit7.innerHTML}`
        );
      };

      interval = setInterval(onInterval, counter);
    }
  }
})();
